<template>
  <div>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <v-toolbar color="primary" dark flat height="32">
        <v-toolbar-title class="subtitle-2 ml-n1"
          >Basic Information</v-toolbar-title
        >
      </v-toolbar>
      <div class="pa-3 text-body-2">
        <v-row
          v-if="typeof currentTable['lastInfo']['c_name'] != 'undefined'"
          dense
        >
          <v-col style="max-width: 200px">Last Name:</v-col>
          <v-col>{{ currentTable["lastInfo"]["c_name"] }}</v-col>
        </v-row>

        <v-row
          v-if="
            typeof currentTable['lastInfo']['c_description'] != 'undefined' &&
            currentTable['lastInfo']['c_description'] != ''
          "
          dense
        >
          <v-col style="max-width: 200px">Last Description:</v-col>
          <v-col>{{ currentTable["lastInfo"]["c_description"] }}</v-col>
        </v-row>

        <v-row dense>
          <v-col style="max-width: 200px">Created At:</v-col>
          <v-col>{{ currentTable["lastInfo"]["n_createDate"] }}</v-col>
        </v-row>
        <v-row v-if="!empty(currentTable['lastInfo']['historyOwner'])" dense>
          <v-col style="max-width: 200px">Created By:</v-col>
          <v-col>{{ currentTable["lastInfo"]["historyOwner"] }}</v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["currentTable"],
};
</script>
